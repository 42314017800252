<template>
  <div id="form-crud-dimension">
    {{ /* LOADER */ }}
    <loading
        :active.sync="loader.isLoading"
        :can-cancel="false"
        :is-full-page="loader.fullPage"
        :opacity="loader.opacity"
        :color="loader.color"
    ></loading>

    <div class="card border-0 shadow p-3">
      <form @submit.prevent="initSubmit">

        <div class="form-group">
          <label for="form-name">{{ $t('common.name') }} *</label>
          <input class="form-control" id="form-name" v-model="dimension.name" type="text">
        </div>

        <div class="form-group">
          <label for="form-height">{{ $t('common.height') }} *</label>
          <input class="form-control" id="form-height" v-model="dimension.height" type="number" step="0.01">
        </div>

        <div class="form-group">
          <label for="form-witdh">{{ $t('common.width') }} *</label>
          <input class="form-control" id="form-witdh" v-model="dimension.width" type="number" step="0.01">
        </div>

        <div class="form-group">
          <label for="select-cat">{{ $tc('common.category', 1) }} *</label>

          <select id="select-cat" class="form-control" v-model="selectedCategory" @input="changeCategory">
            <option
                v-for="(category, index) in CategoryModule.categories"
                :key="index"
                :value="category.id"
            >
              {{ category.name }}
            </option>
          </select>
        </div>

        <input type="submit" class="btn mt-4 mb-2 btn-success" :value="valueButtonSubmit" />
      </form>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    dimension: Object,
    selectedCategory: Number,
    id: Number,
    actionForm: String
  },
  data() {
    return {
      loader: {
        opacity: 0.9,
        isLoading: false,
        fullPage: true,
      },
      selectCategory: this.selectedCategory,
    }
  },
  created: function() {
    this.$store.dispatch('fetchCategories', {
      perPage: null,
      page: null
    })

    if (this.actionForm === 'edit') {
      this.dimension.height = this.dimension.height / 10
      this.dimension.width = this.dimension.width / 10
    }
  },
  watch: {
    selectedCategory: function() {
      this.selectCategory = this.selectedCategory
    }
  },
  methods: {
    /*----------------- LOADER ------------------*/
    showLoader() {
      this.loader.isLoading = true
    },
    hideLoader() {
      this.loader.isLoading = false
    },
    /*----------------- SELECT ------------------*/
    changeCategory() {
      this.$emit('selectCategory',this.dimension.category)
    },
    /*----------------- SUBMIT ------------------*/
    initSubmit()
    {
      // Handle var
      this.dimension.category = this.selectCategory

      // If dimension (create or update)
      if(this.actionForm === 'edit') {
        this.edit()
      } else {
        this.create()
      }
    },
    /**
     * Create dimension (submit form)
     */
    create() {

      this.showLoader()

      this.$store.dispatch('createDimension', this.dimension)
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Update paper (submit form)
     */
    edit() {
      this.showLoader()

      this.$store.dispatch('updateDimension', { id: this.dimension.id, dimension: this.dimension })
          .then(() => {
            this.hideLoader()
            this.redirectAndRefresh()
          })
          .catch((errors) => {
            this.hideLoader()
            this.errorsForm = errors
          })
    },
    /**
     * Redirect to dimension-list after submit
     * Refresh ErrorsForm
     */
    redirectAndRefresh()
    {
      // Redirect
      this.$router.push({ name: 'dimension-index' })

      // Refresh errors form
      this.errorsForm = []
    }
  },
  computed: {
    /**
     * Display value for button submit
     * @returns {string}
     */
    valueButtonSubmit() {
      let value = ''
      if(this.actionForm === 'edit')
      {
        value = this.$t('pages.dimension.editDimension')
      }
      else {
        value = this.$t('pages.dimension.createDimension')
      }

      return value
    },
    ...mapState(['DimensionModule']),
    ...mapState(['CategoryModule'])
  },
  components: {
    Loading: () => import('vue-loading-overlay')
  }
}
</script>
